import * as React from "react"
import { graphql } from "gatsby"
import { Nav } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import YouTubeVideo from "../components/youtube-video"
import VideosNav from "../components/videos-nav"

const VideosPageTemplate = ({
  categories,
  location,
  pageContext,
}) => {
  // Get the current category
  let currentIndex = 0
  for (let i = 0; i < categories.length; i++)
    if (categories[i].fields.slug === pageContext.slug) {
      currentIndex = i
      break
    }
  const [activeKey, setActiveKey] = React.useState(currentIndex)

  // Get the current page
  let page = 1
  if (location.query) {
    if (location.query.page) page = parseInt(location.query.page)
  }
  const [currentPage, setCurrentPage] = React.useState(page)
  const pageSize = 5
  const disableBack = currentPage <= 1
  const disableNext =
    Math.floor(categories[activeKey].frontmatter.videos.length / pageSize) <
    currentPage

  let nav = (
    <Nav
      className="mb-3"
      fill
      activeKey={currentPage}
      onSelect={selectedKey => setCurrentPage(parseInt(selectedKey))}
    >
      <Nav.Item>
        <Nav.Link eventKey={currentPage - 1} disabled={disableBack}>
          {disableBack ? "" : "Back"}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey={currentPage + 1} disabled={disableNext}>
          {disableNext ? "" : "Next"}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )

  return (
    <>
      <Seo
        title={
          pageContext.slug === "/videos/"
            ? "Videos"
            : categories[activeKey].frontmatter.title + " | Videos"
        }
      />
      <VideosNav
        activeKey={activeKey}
        categories={categories}
        setActiveKey={setActiveKey}
        setCurrentPage={setCurrentPage}
      />
      <h3>{categories[activeKey].frontmatter.title}</h3>
      {nav}
      {categories[activeKey].frontmatter.videos &&
      categories[activeKey].frontmatter.videos.length > 1 ? (
        categories[activeKey].frontmatter.videos
          .slice(pageSize * (currentPage - 1), pageSize * currentPage)
          .map((video, i) => {
            let embedBody
            if (video.url) {
              if (video.url.includes("youtu"))
                embedBody = <YouTubeVideo url={video.url} />
            } else if (video.file)
              embedBody = (
                <video src={video.file} loading="lazy" controls>
                  <track kind="captions" />
                </video>
              )
            return (
              <div key={`${video.title}-${i}`}>
                <div className="ratio ratio-16x9 mb-1">{embedBody}</div>
                <h4>{video.title}</h4>
                <p>{video.description}</p>
              </div>
            )
          })
      ) : (
        <p>No videos yet!</p>
      )}
      {nav}
    </>
  )
}

const VideosPage = ({ data, location, pageContext }) => {
  return (
    <Layout title={"Videos"}>
      <VideosPageTemplate
        categories={data.allMarkdownRemark.nodes}
        location={location}
        pageContext={pageContext}
      />
    </Layout>
  )
}

export default VideosPage

export const pageQuery = graphql`
  query Videos {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(videos)/" }
        fields: { slug: { ne: "/videos/" } }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          videos {
            title
            description
            url
            file
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
