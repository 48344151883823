import React from "react"
import { connect } from "react-redux"

class YouTubeVideo extends React.Component {
  state = {
    status: 0,
  }

  constructor(props) {
    super(props)
    const { id, url } = props
    if (!id && url) {
      let regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
      let match = url.match(regExp)
      if (match && match[1].length === 11) this.id = match[1]
    } else this.id = id

    this.onPlayerStateChange = this.onPlayerStateChange.bind(this)
  }

  onPlayerStateChange(e) {
    if (e.data === 1) {
      // PLAYING
      // Pause the livestream
      this.props.setPlayerStatus({ status: "paused" })
      // this.props.setMuted(false)
    }
    this.setState({ status: e.data })
  }

  componentDidMount() {
    this.player = new window.YT.Player(`youtube-${this.id}`, {
      events: {
        onStateChange: this.onPlayerStateChange,
      },
    })
  }

  componentWillUnmount() {
    if (this.state.status === 1 || this.state.status === 3) {
      // PLAYING or BUFFERING
      this.props.setVideo({
        id: this.id,
        timestamp: this.player.getCurrentTime(),
      })
    }
  }

  render() {
    return (
      <iframe
        id={`youtube-${this.id}`}
        src={`https://www.youtube.com/embed/${this.id}?enablejsapi=1`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loading="lazy"
      />
    )
  }
}

const mapStateToProps = ({ player }) => ({ ...player })

const mapDispatchToProps = dispatch => ({
  setMuted: muted => dispatch({ type: "PLAYER_UPDATE_MUTE", payload: muted }),
  setPlayerStatus: ({ status }) =>
    dispatch({ type: "PLAYER_UPDATE_STATUS", payload: status }),
  setVideo: payload => dispatch({ type: "VIDEO_UPDATE_ID", payload: payload }),
  setVolume: volume =>
    dispatch({ type: "PLAYER_UPDATE_VOLUME", payload: volume }),
})

export default connect(mapStateToProps, mapDispatchToProps)(YouTubeVideo)
