import * as React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"

const VideosNav = ({ activeKey, categories, setActiveKey, setCurrentPage }) => {
  return (
    <Nav
      className="mb-3"
      fill
      activeKey={activeKey}
      onSelect={selectedKey => {
        setActiveKey(selectedKey)
        setCurrentPage(1)
      }}
    >
      {categories.map((category, i) => (
        <Nav.Item key={category.fields.slug}>
          <Nav.Link as={Link} to={category.fields.slug} eventKey={i}>
            {category.frontmatter.title}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

export default VideosNav
